.large-svg{
  width: 200px;
  height: 200px;
}

.medium-svg{
  width: 48px;
  height: 48px;
}

#contact{
  text-align: center;
}

.email{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px 10px 0px;
  font-size: 20px;
}


.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form > *{
  margin: 10px;
}

fieldset{
  width: 70%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

textarea, input{
  width: 100%;
  background-color: rgba(0,0,0,0);
  border: 1.5px solid rgba(132, 99, 195, 0.5);
  margin: 5px;
}

button{
  width: 100px;
  height: 30px;
  background-color: rgba(132, 99, 195, 0.5);
  border-radius: 2px;
  border: none;
  color:  white;
} 