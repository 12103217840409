@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
.App {
}

body {
  min-height: 100vh;
  /* background: rgb(254,152,40);
  background: linear-gradient(rgba(254,152,40,0.6), rgba(254,152,40,0.2));
  color: #8463c3; */
  margin: 20px 30px;
  font-family: "Josefin Sans", cursive;
}

svg {
  /* fill: #8463c3; */
  margin: 0px 10px;
  width: 24px;
  height: 24px;
  padding: 0px;
}

.small-svg:hover {
  fill: white;
}

.large-svg:hover {
  fill: white;
}

.main {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  text-align: center;
}

a {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /* color: #8463c3; */
}

.screen {
  margin: 0px;
  width: 80%;
}

body {
  background: linear-gradient(rgba(254, 152, 40, 0.6), rgba(254, 152, 40, 0.2));
  color: #8463c3;
}

svg {
  fill: #8463c3;
}

a {
  color: #8463c3;
}

@media screen and (max-width: 600px) {
  body {
    margin: 15px;
  }

  .screen {
    width: 100%;
    margin: 15px 0px;
  }
}

h1{
  font-size: 50px;
  margin: 0px;
}

.header{
  display: flex;
  flex-direction: column;
 text-align: center;
  padding: 0px;
}

.header-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switchButton{
  background-color: rgba(0,0,0,0);
  border: none;
}

.logo{
  height: 20px;
  object-fit: cover;
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
}

.social{
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

nav{
  margin: 40px; 
}

nav > a{
  margin: 20px;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 600px){
  .header{
    flex-direction:column;
    align-items: flex-start;
  }
  .header-top{
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }
  nav{
    margin: 0px;
  }

  nav > a{
    margin: 0px 20px 0px 0px;
  }

  .social{
    flex-direction: column;
    margin: 0; 
  }

  h1{
    font-size: 40px;
    /* margin: 0px 0px 20px 0px; */
  }

}


.carousel{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.arrow >svg{
  height: 60px;
  width: auto;
}

.project{
  width: 40vw;
  margin: 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quick-info{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconSVG{
  width: 150px;
  height: auto;
  object-fit: cover;
  margin: 0px 10px 0px 0px;
  stroke-width: 0.5px;
}

.name-links{
  display: flex;
  flex-direction: column;
  justify-content: flex start;
  padding: 20px 0px 0px 0px;
}

.name-links *{
  margin: 0px 0px 10px 0px;
  padding: 0px;
}

.name-links > a > img{
  margin: 0px;
}

.project > p {
  padding: 0px;
}

.project > .highlights{
  color: #1d334d;
}


@media screen and (max-width: 600px){
.project{
  margin: 40px 20px;
  width: 90%;
}
 .quick-info{
   flex-direction: column;
   align-items: center;
 }

 .name-links{
   flex-flow: row wrap;
   justify-content: space-between;
   align-items: center;
 }

 .name-links > h4 {
   margin: 0px 100px 0px 0px;
 }

 .carousel{
  flex-direction: column;
  height: auto;
}

.arrow >svg{
  height: 30px;
}

.arrowText{
  display: hidden;
}


}
.inactive {
  display: none;
}
.active {
  display: block;
}
#about{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.about-main{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
}


img.large {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10%;
}

.text{
  height: 100%;
  width: 60%;
}

h3{
  padding: 50px 0px 0px 0px;
  margin: 0px;
}

p{
  padding: 20px 0px;
}

.subtitle{
  margin: 0px;
  padding: 0px;
}

.subtitle:hover{
  /* color: #8463c3; */
}

#PoemClub{
  font-weight: bolder;
  text-decoration: underline;
}

@media screen and (max-width: 600px){
  .about-main{
    flex-flow: column;
    width: 100%;
    align-items: center;
  }
  img.large{
    width: 150px;
    height: 150px;
  }
  .text{
    width: 90%;
  }
}

.large-svg{
  width: 200px;
  height: 200px;
}

.medium-svg{
  width: 48px;
  height: 48px;
}

#contact{
  text-align: center;
}

.email{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px 10px 0px;
  font-size: 20px;
}


.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form > *{
  margin: 10px;
}

fieldset{
  width: 70%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

textarea, input{
  width: 100%;
  background-color: rgba(0,0,0,0);
  border: 1.5px solid rgba(132, 99, 195, 0.5);
  margin: 5px;
}

button{
  width: 100px;
  height: 30px;
  background-color: rgba(132, 99, 195, 0.5);
  border-radius: 2px;
  border: none;
  color:  white;
} 
