.carousel{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.arrow >svg{
  height: 60px;
  width: auto;
}

.project{
  width: 40vw;
  margin: 0px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quick-info{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconSVG{
  width: 150px;
  height: auto;
  object-fit: cover;
  margin: 0px 10px 0px 0px;
  stroke-width: 0.5px;
}

.name-links{
  display: flex;
  flex-direction: column;
  justify-content: flex start;
  padding: 20px 0px 0px 0px;
}

.name-links *{
  margin: 0px 0px 10px 0px;
  padding: 0px;
}

.name-links > a > img{
  margin: 0px;
}

.project > p {
  padding: 0px;
}

.project > .highlights{
  color: #1d334d;
}


@media screen and (max-width: 600px){
.project{
  margin: 40px 20px;
  width: 90%;
}
 .quick-info{
   flex-direction: column;
   align-items: center;
 }

 .name-links{
   flex-flow: row wrap;
   justify-content: space-between;
   align-items: center;
 }

 .name-links > h4 {
   margin: 0px 100px 0px 0px;
 }

 .carousel{
  flex-direction: column;
  height: auto;
}

.arrow >svg{
  height: 30px;
}

.arrowText{
  display: hidden;
}


}