#about{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.about-main{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
}


img.large {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 10%;
}

.text{
  height: 100%;
  width: 60%;
}

h3{
  padding: 50px 0px 0px 0px;
  margin: 0px;
}

p{
  padding: 20px 0px;
}

.subtitle{
  margin: 0px;
  padding: 0px;
}

.subtitle:hover{
  /* color: #8463c3; */
}

#PoemClub{
  font-weight: bolder;
  text-decoration: underline;
}

@media screen and (max-width: 600px){
  .about-main{
    flex-flow: column;
    width: 100%;
    align-items: center;
  }
  img.large{
    width: 150px;
    height: 150px;
  }
  .text{
    width: 90%;
  }
}
