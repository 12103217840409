@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

html {
  scroll-behavior: smooth;
}
.App {
}

body {
  min-height: 100vh;
  /* background: rgb(254,152,40);
  background: linear-gradient(rgba(254,152,40,0.6), rgba(254,152,40,0.2));
  color: #8463c3; */
  margin: 20px 30px;
  font-family: "Josefin Sans", cursive;
}

svg {
  /* fill: #8463c3; */
  margin: 0px 10px;
  width: 24px;
  height: 24px;
  padding: 0px;
}

.small-svg:hover {
  fill: white;
}

.large-svg:hover {
  fill: white;
}

.main {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  text-align: center;
}

a {
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /* color: #8463c3; */
}

.screen {
  margin: 0px;
  width: 80%;
}

body {
  background: linear-gradient(rgba(254, 152, 40, 0.6), rgba(254, 152, 40, 0.2));
  color: #8463c3;
}

svg {
  fill: #8463c3;
}

a {
  color: #8463c3;
}

@media screen and (max-width: 600px) {
  body {
    margin: 15px;
  }

  .screen {
    width: 100%;
    margin: 15px 0px;
  }
}
