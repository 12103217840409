h1{
  font-size: 50px;
  margin: 0px;
}

.header{
  display: flex;
  flex-direction: column;
 text-align: center;
  padding: 0px;
}

.header-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.switchButton{
  background-color: rgba(0,0,0,0);
  border: none;
}

.logo{
  height: 20px;
  object-fit: cover;
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
}

.social{
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
}

nav{
  margin: 40px; 
}

nav > a{
  margin: 20px;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 600px){
  .header{
    flex-direction:column;
    align-items: flex-start;
  }
  .header-top{
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }
  nav{
    margin: 0px;
  }

  nav > a{
    margin: 0px 20px 0px 0px;
  }

  .social{
    flex-direction: column;
    margin: 0; 
  }

  h1{
    font-size: 40px;
    /* margin: 0px 0px 20px 0px; */
  }

}

